<!-- 人脸终端待机背景设置 -->
<template>
    <div id="faceDeviceBgImageModel" ref="faceDeviceBgImageModel" >
        <a-modal v-model="visible" :title="title" centered :width="1120" :getContainer="() => this.$refs.faceDeviceBgImageModel" :destroyOnClose="true" v-drag >
            <template slot="footer">
                <a-button key="back" @click="handleCancel"> {{ $t('facialInfo.a33') }} </a-button>
            </template>
            <div class="bg-main">
                <!-- 人脸终端 -->
                    <div v-loading="sloading" :element-loading-text="$t('facialInfo.a29')">
                        <!-- 人脸终端--标题 -->
                        <div class="bg-title" >
                            <span>{{ $t('facialInfo.a34') }}</span>
                            <a-button v-if="devices.length===1"  type="primary" @click="saveDevice" > {{ $t('facialInfo.a35') }} </a-button>
                        </div>
                        <!-- 批量终端设备名称显示 -->
                        <div v-if="devices.length > 1" class="bg-device" >
                            <a-tag v-for="(item,index) in devices" :key="index">{{ item.faceDeviceName ? item.faceDeviceName : ''}}</a-tag>
                        </div>
                        <!-- 单条终端信息显示 -->
                        <div v-else class="bg-device2" >
                            <a-form-model :model="device" layout='horizontal'>
                                <a-row :gutter="32">
                                    <!-- 设备名称 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a3')">
                                            <a-input v-model="device.faceDeviceName" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 设备序列号 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a10')">
                                            <a-input v-model="device.faceDeviceNumber" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 设备类型 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a12')">
                                            <a-select v-model="device.deviceBrand" style="width: 100%">
                                                <a-select-option v-for="(brand,idx) in brands" :value="brand.no" :key="idx" >
                                                    {{ brand.name }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 设备IP -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a13')">
                                            <a-input v-model="device.deviceIp" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 图片端口 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a36')">
                                            <a-input v-model="device.imgWay" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 设备密码 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a14')">
                                            <a-input-password v-model="device.devicePassWord" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 心跳回调URL -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a15')">
                                            <a-input v-model="device.deviceHeartUrl" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 认证回调URL -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a16')">
                                            <a-input v-model="device.deviceIdentifyUrl" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 设备回调URL -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a17')">
                                            <a-input v-model="device.middlewareUrl" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 设备备注 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a18')">
                                            <a-input v-model="device.faceDevice" allow-clear />
                                        </a-form-model-item>
                                    </a-col>
                                    <!-- 关联读卡器 -->
                                    <a-col :span="6">
                                        <a-form-model-item :label="$t('facialInfo.a11')">
                                            <a-button style=" pointer-events: none; backgroundColor: #fff;text-align: left;width: 100%;">
                                                 {{ device.readerName }}
                                            </a-button>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                            </a-form-model>
                        </div>
                    </div>

                    <!-- 背景图片 -->
                    <div v-if="device.deviceBrand===4" v-loading="uloading" :element-loading-text="$t('facialInfo.a29')" class="bg-container">
                        <!-- 背景图片--标题 -->
                        <div class="bg-title" >
                            <span>{{ $t('facialInfo.a37') }}</span>
                            <!-- 删除按钮 -->
                            <a-button  type="primary" @click="deleteAllBgImage" > {{ $t('facialInfo.a38') }} </a-button>
                        </div>
                        <!-- 图片显示区域 -->
                        <div class="bg-line">
                            <div class="bg-box" v-for="(item,index) in images" :key="index">
                                <div class="bg-label">
                                {{item.index}}
                                </div>
                                <img v-if="item.base64" class="bg-image" :src="item.base64" alt="avatar" @error="(e)=>{ imgOnError(e,item,1) }" />
                                <a-icon v-else class="bg-icon" type="file-image" />
                                <div  class="bo-tool">
                                    <a-button  type="primary" @click="deleteFaceBgImage(item)" > {{ $t('facialInfo.a39') }} </a-button>
                                    <a-upload :show-upload-list="false"  @change="(file)=>{ return handleChange(file,item);}" :before-upload="beforeUpload" > 
                                        <a-button> <a-icon type="upload" /> {{ $t('facialInfo.a40') }} </a-button>
                                    </a-upload>
                                </div>
                            </div>
                        </div>
                        <!-- 上传图片按钮 -->
                        <div class="bg-tool" style="clear: both;">
                            <a-button  type="primary" @click="uploadBgImage" > {{ $t('facialInfo.a41') }} </a-button>
                        </div>
                    </div>

                    <!-- 设备现场图 -->
                    <div v-if="device.deviceBrand === 4 && devices.length === 1" v-loading="rloading" :element-loading-text="$t('facialInfo.a29')">
                        <!-- 背景图片--标题 -->
                        <div  class="bg-title" >
                            <span>{{ $t('facialInfo.a42') }}</span>
                        </div>
                        <div class="bg-line">
                            <!-- 图片显示区域 -->
                            <div class="bg-box" v-for="(item,index) in images" :key="index">
                                <div class="bg-label"> {{item.index}} </div>
                                <img v-if="item.oimage && item.oimage!==''" class="bg-image" :src="item.oimage" alt="avatar" @error="(e)=>{imgOnError(e,item,2)}" />
                                <a-icon v-else class="bg-icon" type="file-image" />
                                <div class="bg-img-tool">
                                    <a-button  type="primary" @click="readDeviceBgImage2(item.index)" > {{ $t('facialInfo.a43') }} </a-button>
                                </div>
                            </div>
                        </div>
                        <!-- 读取全部图片按钮 -->
                        <div class="bg-tool" style="clear: both;">
                            <a-button  type="primary" @click="readDeviceBgImage" > {{ $t('facialInfo.a44') }} </a-button>
                        </div>
                    </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { changeSaveBgImage,deleteBgImage,getFaceDeviceBgImage,getFaceDeviceBgImageByIndex} from "../../api/face";
import { setCompile } from "../../api/door";
import moment from "moment";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function getImgPx(file) {
    const image = new Image();
    image.src = file;
    return new Promise((resolve) => {
        image.onload = () =>{
            const width = image.width;
            const height = image.height;
            resolve({width,height});
        }
    });
}

const brands =[
    {no: 0, key: "0@@-@@1", name: "请选择"},
    {no: 1, key: "1@@-@@1", name: "宇泛"},
    {no: 2, key: "2@@-@@1", name: "奇智"},
    {no: 3, key: "3@@-@@1", name: "海康"},
    {no: 4, key: "4@@-@@1", name: "海康-HEOP"}
];

export default {
  name: 'faceDeviceBgImageModel',
  props: {
    // title: {
    //   type: String,
    // //   default: this.$t('facialInfo.a45'),
    // },
    devices: {
      type: Array,
      default:[],
    },
    
  },
  data() {
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata260')));
        } else {
            callback();
        }
    };
    return {
        title:this.$t('facialInfo.a45'),
        loading:false,
        sloading:false,
        uloading:false,
        rloading:false,
        visible:false,
        images:[         
        ],
        device:{
            siteId: 0,
            deviceBrand: 0,
            readerId: 0,
            readerName: "",
            faceDeviceNO: "",
            faceDeviceName: "",
            faceDeviceNumber: "",
            faceDeviceStatus: "",
            deviceIp: "",
            devicePassWord: "",
            deviceHeartUrl: "",
            deviceIdentifyUrl: "",
            middlewareUrl: "",
            faceDevice: "",
            imgWay: null,
            imgType: null
        },
        brands,
        brand:0

    };
  },
  mounted(){
      
  },
  watch:{
    "$parent.faceDeviceImageVisible":{
      handler(val){
          if(val){              
              this.visible = val;
              if(this.devices.length===1){
                  this.device = this.devices[0];
                  this.brand = this.device.deviceBrand;
                  if(this.brand===4){

                      this.readDeviceBgImage();
                  }

              }else{
                this.device = this.devices[0];
                this.brand = this.device.deviceBrand;
              }
              this.images = [];
              for(let n=1;n<6;n++){
                  this.images.push({index:n,msg:'',image:'',oimage:''});
              }
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
             this.$parent.faceDeviceImageVisible = val;
             this.images = [];
             this.brand = 4;
          }
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
    imgOnError(e,item,tag){
        console.log(tag);
        if(tag===2 && item.oimage !=''){
            this.images[item.index-1].oimage = '';
        } else if(tag===1 && item.base64 !='') {
            this.images[item.index-1].image = '';
            this.images[item.index-1].base64 = '';
        }
    },
    async handleChange(e,item){
        const {file} = e;
        this.uloading = true;
        item.change = true;
        if(file.originFileObj){
            item.base64 = await getBase64(file.originFileObj);
        }else{
            item.base64 = await getBase64(file);
        }
        // console.log(await getImgPx(item.base64));
        this.uloading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      
      if (!isJpgOrPng) {
        this.$message.error(this.$t('facialInfo.a46'));
        return false;
      }
      const isLt2M = file.size / 1024  < 300;
      if (!isLt2M) {
            this.$message.error(this.$t('facialInfo.a47'));
            return false;
      }
    //   const base64 = (await getBase64(file));
    //   const img = (await getImgPx(base64));
    //   const pxrate = img.width/img.height;
    //   const ispx = (pxrate>=0.55 && pxrate<=0.65)
    //   if(!ispx){
    //        this.$message.error('图片分辨率请尽量控制在600*1024左右');
    //        return false;
    //   }

    //   item.base64 = base64;
      return false;
    },
    // 编辑的确定按钮方法
    saveDevice() {
      
      let data = this.device;
      this.$confirm({
            title:  this.$t('facialInfo.a48'),//
            centered: true,
            onOk: () => {
                this.sloading = true;
                setCompile(data)
                .then((res) => {
                    this.sloading = false;
                    if (res.errorCode == "00") {
                        this.$message.success(res.msg);
                    } else if (res.errorCode == "02") {
                        this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    this.sloading = false;
                    console.log(err);
                });
            },
        }); 
    },
    // 编辑的取消按钮方法
    handleCancel() {
      this.visible = false;
    },
    readDeviceBgImage3(){
        this.images.forEach(element => {
            if(element.change){
                this.readDeviceBgImage2(element.index);
            }
        });
        
    },
    readDeviceBgImage4(images){
        images.forEach(element => {
            if(element.change){
                this.readDeviceBgImage2(element.index);
            }
        });
    },
    readDeviceBgImage2(imageIndex){
        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          id: this.device.faceDeviceNO,
          imageIndex:imageIndex,
        };
        this.rloading = true;
        this.uloading = true;
        getFaceDeviceBgImageByIndex(data)
        .then((res) => {
            this.rloading = false;
            this.uloading = false;
            if (res.errorCode == "00") {
                // this.$message.success(res.msg);
                this.images[imageIndex-1] = {};
                this.images[imageIndex-1] = res.data.image;
                this.images[imageIndex-1].change = false;
            }
        })
        .catch((err) => {
            this.rloading = false;
            this.uloading = false;
            console.log(err);
        });
    },
    readDeviceBgImage(){
        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          id: this.device.faceDeviceNO
        };
        this.rloading = true;
        this.uloading = true;
        getFaceDeviceBgImage(data)
        .then((res) => {
            this.rloading = false;
            this.uloading = false;
            if (res.errorCode == "00") {
                // this.$message.success(res.msg);
                this.images = [];
                this.images = res.data.images;
                this.images.forEach(element => {
                    this.images.change = false;
                });
            }
        })
        .catch((err) => {
            this.rloading = false;
            this.uloading = false;
            console.log(err);
        });

        
    },
    uploadBgImage(){
        let idList = [];
        
        this.devices.forEach(element => {
            idList.push(element.faceDeviceNO);
        });

        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          idList: idList,
          images:this.images
        };
        
        console.log(data)
        this.$confirm({
            title:  this.$t('facialInfo.a49'),//
            centered: true,
            onOk: () => {
                this.uloading = true;
                changeSaveBgImage(data)
                .then((res) => {
                    this.uloading = false;
                    const { errorCode, msg } = res;
                    if (errorCode == "00" || errorCode == "03") {
                        this.$message.success(msg);
                    } else {
                        this.$message.error(msg);
                    }
                    this.readDeviceBgImage4(data.images);

                })
                .catch((err) => {
                    this.uloading = false;
                    console.log(err);
                });
            },
        }); 
    },

    deleteFaceBgImage(item){
        let idList = [];
        idList.push(this.device.faceDeviceNO);
        let images = [];
        images.push(item);
        this.deleteFaceImage(idList,images,this.$t('facialInfo.a50'));
    },
    deleteAllBgImage(){
        let idList = [];
        this.devices.forEach(element => {
            idList.push(element.faceDeviceNO);
        });

        this.deleteFaceImage(idList,this.images,this.$t('facialInfo.a51'));
    },
    deleteFaceImage(idList,images,msg){
        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          idList: idList,
          images:images,
        };
        
        console.log(data)
        this.$confirm({
            title:  msg,//'确认要上传所选择的图片吗？'
            centered: true,
            onOk: () => {
                this.uloading = true;
                deleteBgImage(data)
                .then((res) => {
                    this.uloading = false;
                    const { errorCode, msg } = res;
                    if (errorCode == "00" || errorCode == "05") {
                        this.$message.success(msg);
                        images.forEach(element => {
                            this.images[element.index-1].base64 = '';
                            this.images[element.index-1].image = '';
                            this.images[element.index-1].oimage = '';
                        });
                    } else {
                        this.$message.error(msg);
                    }
                    
                    
                })
                .catch((err) => {
                    this.uloading = false;
                    console.log(err);
                });
            },
        }); 
    },
    getBrandName(no,item){
      let name = '';
      brands.forEach(element => {
        if(no+''===element.no+''){
          name = element.name;
        }
      });

      return name;
    },
  },
};
</script>
<style scoped>
.bg-device{
    width:100%;
    height: 80px;
    overflow: auto;
    padding: 10px;
    border:1px solid #e8e8e8;
    margin-bottom: 15px;
    border-radius: 3px;
}
.bg-device2{
    width:100%;
    height: 215px;
    overflow: hidden;
    padding: 10px;
    border:1px solid #e8e8e8;
    margin-bottom: 15px;
    border-radius: 3px;
}
.bg-main{
    width:100%;
    height: 600px;
    padding: 20px;
    overflow: auto;
}
.bg-main::after{
    clear: both;
}
.bg-container{
    margin-bottom: 15px;
}
.bg-title{
    padding: 8px 15px ;
    background-color: #e8e8e8;
    font-size:16px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px 3px 0px 0px;
}
.bg-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid rgba(12, 12, 12, 0.15);
    border-right: 1px solid rgba(12, 12, 12, 0.15);
    padding: 10px;
}
.bg-box{
    width: 200px;
    height: 365px;
    border: 1px solid #e8e8e8;
    position: relative;  
    text-align: center;
}
.bg-image{
    padding: 5px;
    width : 100%;
    height: 320px;
}
.bg-icon{
    margin-top:50px;
    padding: 5px;
    width : 100%;
    height: 270px;
    font-size:160px ;
}
.bg-label{
    width: 188px;
    text-align: center;
    height: 30px;
    color: #fff;
    padding: 5px;
    margin: 5px;
    background-color: #000000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
}
.bo-tool{
    display: flex;
    justify-content: space-between;
    padding:0 5px;
}
.bg-tool{
    text-align: right;
    padding:10px;
    border-radius: 0px 0px 3px 3px;
    border: 1px solid rgba(12, 12, 12, 0.15);
}
.bg-img-tool{
    text-align: right;
    padding:5px;
}
div /deep/ .ant-modal-body {
    padding:0px;
}
div /deep/ .ant-form-item-label {
    line-height: 25px
}
div /deep/ .ant-form-item{
    margin-bottom: 0px;
}
.red{
    color: #ff0000;
}
</style>